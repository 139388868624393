import { Injectable, inject } from '@angular/core';
import { formatDate } from '@angular/common';

import {
	IMapperFromDetailsDto,
	IMapperFromDto,
	IValidationErrorMapper,
} from '@ygm/common/core/services/mappers/mappers';
import { EntityValidationErrors } from '@ygm/common/core/models/app-error';
import { ValidationErrorDto } from '@ygm/common/core/services/dto/validation-error.dto';
import { extractErrorMessageByField } from '@ygm/common/core/services/mappers/extract-error-message';
import { ContractStatusMapper } from '@ygm/common/core/services/mappers/contract-status.mapper';
import { ContractTypeMapper } from '@ygm/common/core/services/mappers/contract-type.mapper';
import { MergeData } from '@ygm/common/core/models/mergeData';
import { LevelInstanceMapper } from '@ygm/common/core/services/mappers/level-instance.mapper';
import {
	ContractDetailsDto,
	ContractTypeEditDto,
	SharedCreditDto,
	ContractMergeDto,
	ContractCreationDto,
	ContractDto,
} from '@ygm/common/core/services/dto/contract.dto';
import {
	Contract,
	ContractCreationData,
	ContractDetails,
	ContractEditData,
	SharedCredit,
} from '@ygm/common/core/models/contract';
import { CampaignUserMapper as PublicCampaignUserMapper } from '@ygm/common/core/services/mappers/campaign-user.mapper';
import { ContractAssignmentDto } from 'projects/admin-web/src/app/core/services/dto/contract-assignment.dto';

import { CampaignUserMapper } from '../../../../../../admin-web/src/app/core/services/mappers/campaign-user.mapper';
import { ContractAssignment } from '../dto/contract-assignment';

import { BaseContractMapper } from './base-contract.mapper';
import { CampaignMapper } from './campaign.mapper';
import { ContractPreferredPaymentMethodMapper } from './contract-preferred-payment-method.mapper';

/** Campaign mapper. */
@Injectable({
	providedIn: 'root',
})
export class ContractMapper
implements
		IMapperFromDto<ContractDto, Contract>,
		IMapperFromDetailsDto<ContractDetailsDto, ContractDetails>,
		IValidationErrorMapper<ContractTypeEditDto, ContractEditData> {

	private readonly campaignUserMapper = inject(CampaignUserMapper);

	private readonly contractStatusMapper = inject(ContractStatusMapper);

	private readonly contractTypeMapper = inject(ContractTypeMapper);

	private readonly levelInstanceMapper = inject(LevelInstanceMapper);

	private readonly baseContractMapper = inject(BaseContractMapper);

	private readonly campaignMapper = inject(CampaignMapper);

	private readonly publicCampaignUserMapper = inject(PublicCampaignUserMapper);

	private readonly contractPreferredPaymentMethodMapper = inject(ContractPreferredPaymentMethodMapper);

	/** @inheritdoc */
	public fromDto(dto: ContractDto): Contract {
		return {
			...this.baseContractMapper.fromDto(dto),
			renewalNote: dto.renewal_note,
			contractNote: dto.contract_note,
			isRenewed: dto.is_renewed,
			campaign: this.campaignMapper.fromDto(dto.campaign),
			totalCost: dto.total_cost,
			levelsCount: dto.levels_count,
			privateNote: dto.private_note || null,
			hasMergeableContracts: dto.has_mergeable_contracts,
			sharedCreditsWith: dto.shared_credits_with.map(user => this.publicCampaignUserMapper.fromDto(user)),
			levels: dto.levels.map(level => this.levelInstanceMapper.fromDto(level)),
			preferredPaymentMethod: this.contractPreferredPaymentMethodMapper.fromDto(dto.preferred_payment_method),
		};
	}

	/** @inheritdoc */
	public fromDetailsDto(dto: ContractDetailsDto): ContractDetails {
		return {
			...this.fromDto(dto),
			signature: dto.signature,
			signerIpAddress: dto.signer_ip_address,
		};
	}

	/**
	 * Maps to contract assignment dto.
	 * @param assignments Assignments.
	 */
	public toAssignmentsDto(assignments: ContractAssignment[]): ContractAssignmentDto[] {
		return assignments.map(assignment => ({
			user: assignment.user.id,
			contract: assignment.contractId,
		}));
	}

	/** @inheritdoc */
	public validationErrorFromDto(
		errorDto: ValidationErrorDto<ContractCreationDto>,
	): EntityValidationErrors<ContractCreationData> {
		return {
			sharedCredits: extractErrorMessageByField('shared_credits', errorDto),
			signature: extractErrorMessageByField('signature', errorDto),
			contact: {
				firstName: extractErrorMessageByField('member.first_name', errorDto),
				lastName: extractErrorMessageByField('member.last_name', errorDto),
				email: extractErrorMessageByField('member.email', errorDto),
				mobilePhone: extractErrorMessageByField('member.mobile_phone', errorDto),
				workPhone: extractErrorMessageByField('member.work_phone', errorDto),
			},
			status: extractErrorMessageByField('status', errorDto),
			member: {
				address: extractErrorMessageByField('member.address', errorDto),
				city: extractErrorMessageByField('member.city', errorDto),
				name: extractErrorMessageByField('member.name', errorDto),
				state: extractErrorMessageByField('member.state', errorDto),
				zipcode: extractErrorMessageByField('member.zipcode', errorDto),
			},
			createdBy: extractErrorMessageByField('created_by_id', errorDto),
			levels: extractErrorMessageByField('levels', errorDto),
			type: extractErrorMessageByField('type', errorDto),
			privateNote: extractErrorMessageByField('private_note', errorDto),
		};
	}

	/**
	 * Map to contract type edit dto.
	 * @param data Contract type edit data.
	 */
	public mapToContractTypeEditDto(data: ContractEditData): ContractTypeEditDto {
		return {
			type: this.contractTypeMapper.toDto(data.type),
			levels: data.levels.map(level => ({
				id: level.id,
				level_id: level.levelId,
				trade_with: level.tradeWith,
				cost: level.cost,
				is_declined: !level.isApproved,
			})),
			shared_credits: data.sharedCredits.map(sharedCredit => ({
				user_campaign_id: sharedCredit.userId,
				amount: sharedCredit.amount,
			})),
			member: {
				email: data.member.email,
				first_name: data.member.firstName,
				last_name: data.member.lastName,
				mobile_phone: data.member.mobilePhone,
				work_phone: data.member.workPhone,
				address: data.member.address,
				city: data.member.city,
				name: data.member.name,
				state: data.member.state,
				stored_member_id: data.member.storedMemberId,
				zipcode: data.member.zipcode,
			},
			signature: data.signature,
			status: this.contractStatusMapper.toDto(data.status),
			preferred_payment_method: this.contractPreferredPaymentMethodMapper.toDto(data.preferredPaymentMethod),
			private_note: data.privateNote ?? '',
		};
	}

	/**
	 * Map to contract creation dto.
	 * @param data Contract creation data.
	 */
	public toContractCreationDto(data: ContractCreationData): ContractCreationDto {
		return {
			type: this.contractTypeMapper.toDto(data.type),
			levels: data.levels.map(level => ({
				id: level.id,
				level_id: level.levelId,
				trade_with: level.tradeWith,
				cost: level.cost,
				is_declined: !level.isApproved,
			})),
			shared_credits: data.sharedCredits.map(sharedCredit => ({
				user_campaign_id: sharedCredit.userId,
				amount: sharedCredit.amount,
			})),
			member: {
				address: data.member.address,
				city: data.member.city,
				name: data.member.name,
				state: data.member.state,
				stored_member_id: data.member.id,
				zipcode: data.member.zipcode,
				email: data.contact.email,
				first_name: data.contact.firstName,
				last_name: data.contact.lastName,
				mobile_phone: data.contact.mobilePhone,
				work_phone: data.contact.workPhone,
			},
			signature: data.signature,
			status: this.contractStatusMapper.toDto(data.status),
			created_by_id: data.createdBy.id,
			created_date: formatDate(new Date().toISOString(), 'MM/dd/YYYY', 'en-US'),
			note: '',
			preferred_payment_method: this.contractPreferredPaymentMethodMapper.toDto(data.preferredPaymentMethod),
			private_note: data.privateNote ?? '',
		};
	}

	/**
	 * Map shared credit from shared credit dto.
	 * @param dto Shared credit DTO.
	 */
	public fromSharedCreditDto(dto: SharedCreditDto): SharedCredit {
		return {
			id: dto.id,
			amount: dto.amount,
			campaignUser: this.campaignUserMapper.fromDto(dto.user_campaign),
		};
	}

	/**
	 * Map contract merge from contract merge dto.
	 * @param data Contract merge model.
	 */
	public toContractMergeDto(data: MergeData<Contract>): ContractMergeDto {
		return {
			dest_contract_id: data.original.id,
			source_contract_ids: data.sources.map(({ id }) => id),
		};
	}

}
